import type { OptionalLinkProps } from '@/components/OptionalLink';
import { OptionalLink } from '@/components/OptionalLink';
import { ClassNameProvider } from '@/styles/Tabs';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { MdLockOutline } from 'react-icons/md';
import { Tabs } from './Tabs';

interface ExtendedOptions {
  $link?: OptionalLinkProps['options'];
}

export interface StandaloneTabsProps extends StandaloneComponentProps<typeof Tabs, ExtendedOptions> {
  tabs?: (OptionalLinkProps & {
    isActive?: boolean;
    isLocked?: boolean;
  })[];
}

export const StandaloneTabs: StandaloneComponent<StandaloneTabsProps> = ({ tabs, options, ...props }) => {
  const { $tab, $link, ...$base } = options ?? {};

  return (
    <ClassNameProvider value={$base}>
      <Tabs {...$base} {...props}>
        {(tabs ?? []).map(({ content, isActive, isLocked, ...link }, index) => {
          return (
            <OptionalLink
              key={index}
              content={
                <Tabs.Tab data-active={isActive ? '' : undefined} data-locked={isLocked ? '' : undefined} {...$tab}>
                  {isLocked && <MdLockOutline />}
                  {content}
                </Tabs.Tab>
              }
              {...$link}
              {...link}
            />
          );
        })}
      </Tabs>
    </ClassNameProvider>
  );
};
