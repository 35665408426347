/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneTabs as ResolvedStandaloneTabs } from 'base/components/standalone/Tabs';

export const Tabs = ResolvedStandaloneTabs;

export type TabsProps = PropsFromComponent<typeof Tabs>;

export const TabsWith = (extras: DynamicStandaloneExtras): typeof Tabs => {
    return function Tabs(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Tabs');
        return <ResolvedStandaloneTabs {...mergeProps({ options: { theme } }, props)} />;
    }
}