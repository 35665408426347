import { AdTemplate } from '@/components/AdTemplate';
import { Base } from '@/components/Base';
import { Button } from '@/components/Button';
import { Consent } from '@/components/Consent';
import { NoInfinityTeaserBoxTitle } from '@/components/NoInfinityTeaserBoxTitle';
import { PageMeta } from '@/components/PageMeta';
import { SchemaMarkup } from '@/components/SchemaMarkup';
import { Spinner } from '@/components/Spinner';
import { Tracking, TrackingProps } from '@/components/Tracking';
import { Meta } from '@/types/content';
import { http } from '@/utils/http';
import { parseAccessLevel } from '@/utils/parseAccessLevel';
import { withContentLocked } from '@/utils/withContentLocked';
import { getPageData } from 'lib/data';
import { removeBySelectors } from 'lib/labrador/content-operations/removeBy';
import { AdsMeta, Content } from 'lib/labrador/types';
import { Cache } from 'lib/services/cache/CacheServiceRedis';
import { isDev } from 'lib/utils';
import getUrl from 'lib/utils/getUrl';
import logger from 'lib/utils/logger';
import { withSerializationGuard } from 'lib/utils/withSerializationGuard';
import { renderPostTypeComponent } from 'modules/dynamic/components';
import { GetServerSideProps, NextPage } from 'next';
import { Fragment, useDeferredValue, useEffect, useRef, useState } from 'react';
import EmbedLogin from '../lib/AlltIdAuth/index';
import { getUserTags } from '../lib/alltIdMyData';
import { getCacheChannels, getCacheTags, isValidPage, stripSlashes } from '../lib/labrador/utils';

const getArticleIDs = (pageData: Content): number[] => {
  const extractTeaserIDs = (contents: Content[]) => {
    let teaserIDs: number[] = [];
    contents.forEach((content) => {
      if (content.type === 'articleTeaser' && content.meta.articleId) teaserIDs.push(content.meta.articleId as number);
      if (content.children.length > 0) teaserIDs = [...teaserIDs, ...extractTeaserIDs(content.children)];
    });
    return teaserIDs;
  };

  if (pageData.type == 'front') return extractTeaserIDs(pageData.children);
  if (pageData.type == 'article') return [pageData.meta.id as number];
  return [];
};

interface PageProps {
  route: string[];
  pageData: Content;
  adsData: AdsMeta;
  device: string;
}

const Page: NextPage<PageProps> = ({ route, pageData, adsData, device }) => {
  const [infinityScrollEnabled, _setInfinityScrollEnabled] = useState(true);
  const infinityScrollEnabledRef = useRef(infinityScrollEnabled);
  const setInfinityScrollEnabled = (data: boolean) => {
    infinityScrollEnabledRef.current = data;
    _setInfinityScrollEnabled(data);
  };
  const [isLoadingNewPage, _setIsLoadingNewPage] = useState(false);
  const isLoadingNewPageRef = useRef(isLoadingNewPage);
  const setIsLoadingNewPage = (data: boolean) => {
    isLoadingNewPageRef.current = data;
    _setIsLoadingNewPage(data);
  };
  const [extraPagesData, _setExtraPagesData] = useState([] as Content[]);
  const extraPagesDataRef = useRef(extraPagesData);
  const setExtraPagesData = (data: Content[]) => {
    // don't want to have them twice
    data = removeBySelectors(['breakingNews', 'trendingMenu'], data);

    extraPagesDataRef.current = data;
    _setExtraPagesData(data);
  };
  const [loadedPageIDs, _setLoadedPageIDs] = useState(getArticleIDs(pageData));
  const loadedPageIDsRef = useRef(loadedPageIDs);
  const setLoadedPageIDs = (data: number[]) => {
    loadedPageIDsRef.current = data;
    _setLoadedPageIDs(data);
  };

  const extraPagesToAutoLoad = 2;
  const hasInfinityScrollPage = !!(pageData.meta.infinityScrollID || '');
  const isArticle = pageData.type === 'article';

  const adsUniqueId = useDeferredValue(extraPagesData?.slice(-1)[0]?.meta?.adsUniqueId?.toString());

  useEffect(() => {
    if (typeof window.DFPInit === 'function') {
      if (adsUniqueId) {
        if (window?.location?.search?.includes('debug')) {
          logger.debug(`DFPInit(): ${adsUniqueId}`);
        }
        window.adsUniqueId = `${adsUniqueId}`;
        window.DFPInit(adsUniqueId);
      } else if (process.env.OCELOT_AD_DEBUG) {
        logger.error(`adsUniqueId is missing`);
      }
    }
  }, [adsUniqueId]);

  useEffect(() => {
    const handleScroll = (event: Event) => {
      const windowHeight = window.innerHeight;
      const documentHeight = Math.max(
        document.body.getBoundingClientRect().height,
        document.documentElement.getBoundingClientRect().height,
      );
      const triggerOffset = windowHeight * 4;
      const scrollPosition = window.scrollY;
      const distanceFromBottom = documentHeight - scrollPosition - windowHeight;

      if (
        infinityScrollEnabledRef.current &&
        distanceFromBottom < triggerOffset &&
        !isLoadingNewPageRef.current &&
        extraPagesDataRef.current.length < extraPagesToAutoLoad
      ) {
        loadNextPage();
      }
    };

    if (hasInfinityScrollPage || isArticle) {
      window.addEventListener('scroll', handleScroll, true);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const loadPageDataAPI = async (route: string[], exclude: number[]) => {
    const url = getUrl('/api/page');

    if (!url) {
      setIsLoadingNewPage(false);
      return;
    }

    const params = new URLSearchParams({
      route: route.join('/'),
      exclude: exclude.join(','),
      device,
    });

    url.search = params.toString();

    const response = await http.get(url.href);
    const data = response?.data as Content;

    if (!data) {
      setIsLoadingNewPage(false);
      setInfinityScrollEnabled(false);
      return;
    }

    setLoadedPageIDs([...loadedPageIDsRef.current, ...getArticleIDs(data)]);
    setExtraPagesData([...extraPagesDataRef.current, data]);

    setIsLoadingNewPage(false);
  };

  const loadNextPage = () => {
    setIsLoadingNewPage(true);

    const isFrontPage = pageData.type === 'front';
    const isArticlePage = pageData.type === 'article';
    const isEtikettPage = pageData.data.hostpath === 'etikett';
    const infinityScrollID = pageData.meta.infinityScrollID as string;
    const infinityScrollMode = pageData.meta.infinityScrollMode as 'front' | 'article';
    const category = pageData.data.category as string;

    if (isFrontPage && isEtikettPage) {
      loadPageDataAPI(route, loadedPageIDsRef.current);
      return;
    }

    if (isFrontPage && infinityScrollID) {
      loadPageDataAPI(['_scroll', 'front', infinityScrollID], loadedPageIDsRef.current);
      return;
    }

    if (isArticlePage && infinityScrollMode === 'front') {
      loadPageDataAPI(['_scroll', 'front', infinityScrollID], loadedPageIDsRef.current);
      setInfinityScrollEnabled(false);
      return;
    }

    if (isArticlePage && infinityScrollMode === 'article') {
      loadPageDataAPI(['_scroll', 'article', category], loadedPageIDsRef.current);
      return;
    }
  };

  const analyticsData = (pageData.data.analyticsData || {}) as TrackingProps;

  return (
    <Base
      content={
        <>
          <Consent />
          <PageMeta pageData={pageData} />
          <AdTemplate pageMeta={pageData?.meta as Meta} adsData={adsData} />
          <Tracking {...analyticsData} />
          <SchemaMarkup pageData={pageData} />
          <EmbedLogin />
          {renderPostTypeComponent(pageData)}
          {extraPagesData.length > 0 && (
            <>
              <NoInfinityTeaserBoxTitle />
              {extraPagesData.map((page, index) => (
                <Fragment key={index}>{renderPostTypeComponent(page)}</Fragment>
              ))}
            </>
          )}
          {extraPagesData.length >= extraPagesToAutoLoad && !isLoadingNewPage && infinityScrollEnabled && (
            <Button content="Mer" options={{ onClick: loadNextPage, className: '!flex mx-auto my-10' }} />
          )}
          {isLoadingNewPage && <Spinner />}
          <div id="modal"></div>
        </>
      }
    />
  );
};

const redirect = (destination: string, permanent: boolean = true) => ({ redirect: { destination, permanent } });
const notFound = (): { notFound: true } => ({ notFound: true });

export const getServerSideProps: GetServerSideProps = async (context) => {
  const pageParam = context.params?.page || [];
  const route = typeof pageParam == 'string' ? [pageParam] : pageParam;
  const device = route.shift() || '';

  if (!['mobile', 'desktop'].includes(device)) return notFound();
  if (!route.length) route.push('index');

  // ### Build Page ###
  const buildStart = Date.now();

  const cacheRoute = [device, ...route];
  let data = await Cache.fetchRoute(cacheRoute);

  if (data === null) {
    data = await getPageData(route, { article: [], video: [] }, device);
    if (isValidPage(data.pageData.type)) Cache.storeRoute(cacheRoute, data);
  }
  const buildFinish = Date.now();
  // ### Build Page ###

  // ### Redirect & Status ###
  if (data.pageData.type === '404') {
    return notFound();
  }
  if (data.pageData.type === '500') {
    throw new Error(data.pageData.message || 'Internal Server Error');
  }
  if (data.pageData.meta.permanentRedirect) {
    return redirect(data.pageData.meta.permanentRedirect);
  }
  if (data.pageData.type === 'article' && stripSlashes(data.pageData.data.publishedUrl) !== route.join('/')) {
    return redirect(data.pageData.data.publishedUrl);
  }
  // ### Redirect & Status ###

  logger.info('[BUILD]', `${buildFinish - buildStart} ms`, device, route.join('/'));

  // ### Cache Headers ###
  const cacheControl = process.env.VERCEL
    ? `private, no-cache, no-store, max-age=0, must-revalidate`
    : `public, max-age=${Cache.cloudflareTtl}`;

  const cacheTags = getCacheTags(data.pageData.type, data.pageData.meta.id, data.pageData.data.tags);
  const cacheChannels = getCacheChannels(data.pageData.type, data.pageData.meta.id, data.pageData.data.tags);

  context.res.setHeader('cache-control', cacheControl); // This is set for Cloudflare
  context.res.setHeader('cache-tag', cacheTags); // This is set for Cloudflare

  context.res.setHeader('x-ocelot-cache-external-max-age', Cache.cloudflareTtl); // Cloudflare strips origin Cache-Control and imposes it's own towards the user but we want to see the original value
  context.res.setHeader('x-ocelot-cache-internal-max-age', Cache.redisPageTtl); // We also want to view our internal cache ttl interval
  context.res.setHeader('x-ocelot-cache-tags', cacheTags); // Cloudflare hides Cache-Tags but we still want to be able to view it
  context.res.setHeader('x-ocelot-cache-channels', cacheChannels);
  context.res.setHeader('x-ocelot-cache-build-timestamp', data.pageData.meta.buildDatetime || ''); // Show approximate time when the page was built
  // ### Cache Headers ###

  // ### Locked Content ###
  if (isDev && context.query?.access) {
    const accessLevel = parseAccessLevel(context.query.access as string);
    data.pageData.meta.accessLevel = accessLevel;
  }

  const userTags = getUserTags({ cookies: context.req.cookies, query: context.query });
  const { accessLevel, purchaseTag } = data.pageData.meta ?? {};

  const isOpen = !accessLevel || accessLevel === 'open';
  const isUserAuthorized = Object.hasOwn(context.req.headers, 'x-ocelot-authorized');
  const isSubscribed = isUserAuthorized && accessLevel === 'purchase' && userTags.includes(purchaseTag);

  context.res
    .setHeader('x-ocelot-access-level', parseAccessLevel(accessLevel))
    .setHeader('x-ocelot-required-entitlement', purchaseTag || '');

  const shouldLockContent =
    !isOpen && ((accessLevel === 'login' && !isUserAuthorized) || (accessLevel === 'purchase' && !isSubscribed));

  if (shouldLockContent) withContentLocked(data.pageData);
  // ### Locked Content ###

  return {
    props: withSerializationGuard({
      route,
      pageData: data.pageData,
      adsData: data.adsData,
      device,
    }),
  };
};

export default Page;
