/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "tab_colors_default": "bg-secondary-100 data-[active]:bg-primary-300 data-[locked]:text-gray-600",
  "tab_size_default": "px-4 py-2 gap-0.5",
  "tab_variant_default": "flex flex-row items-center rounded-md transition-colors data-[locked]:pointer-events-none",
  "colors_default": "bg-secondary-400",
  "size_default": "p-1 gap-1 rounded-md",
  "variant_default": "inline-flex flex-row items-center justify-center mx-auto"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Tabs");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;