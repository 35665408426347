/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneOptionalLink as ResolvedStandaloneOptionalLink } from 'base/components/standalone/OptionalLink';

export const OptionalLink = ResolvedStandaloneOptionalLink;

export type OptionalLinkProps = PropsFromComponent<typeof OptionalLink>;

export const OptionalLinkWith = (extras: DynamicStandaloneExtras): typeof OptionalLink => {
    return function OptionalLink(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'OptionalLink');
        return <ResolvedStandaloneOptionalLink {...mergeProps({ options: { theme } }, props)} />;
    }
}